@import url('https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.min.css');
@import url('https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/css/bootstrap.min.css');

body {
  /* background: linear-gradient(rgb(15,23,42), rgb(15,23,42), #0F172A); */
  /* background: linear-gradient(rgb(15,23,42), rgb(15,23,42), rgb(34, 45, 71)); */
  color: rgb(226,232,240);
}
a {
  color: inherit;
  text-decoration: none;
}

.note {
  color: rgb(226,232,240);
}

.nav-main {
  position: sticky; 
  top: 0; 
  min-height:500px
}

.nav-link {
  text-decoration: none;
  font-size: 1.4rem;
  color:rgb(226,232,240);
  text-transform: uppercase;
  position: relative;
  overflow: hidden;
  display: block;
}

/* #nav {
  background-color: #334a94;
  height: 300px;
  width: 150px;
  float: left;
  padding: 5px;
}

#section  {
  width: 500px;
  float: left;
  padding: 10px;
} */

.nav-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.first {
  position: absolute;
  transform: translateY(100%);
  transition: all .3s linear;
  padding: 4px;
}

.nav-link:hover .first,
.nav-link.active .first{
  color: rgb(15, 23, 42);
  box-shadow: inset 200px 0 0 0 rgb(226,232,240);
  padding: 4px;
}

.second {
  position: relative;
  display: block;
  transition: all .3s linear;
  padding: 4px;
}

.nav-link:hover .second,
.nav-link.active .second{
  color: rgb(15, 23, 42);
  box-shadow: inset 200px 0 0 0 rgb(226,232,240);
  padding: 4px;
}

.myImg {
  vertical-align: middle;
  width: 250px;
  height: 260px;
  border-radius: 50%;
}

span.skills {
  width: 50%;
  height: 5em;
  margin: 2em auto;
  border-radius: 500px;
  background-color: #000;
}